.ql-editor {
  max-height: 300px;
  overflow-y: auto;

  h1 {
    font-size: 2.5em !important
  }

  h2 {
    font-size: 2em !important;
  }
} 
